'use client';

import {twMerge} from 'tailwind-merge';
import {type MotionProps, motion} from 'framer-motion';
import {type ForwardedRef, createElement, forwardRef} from 'react';
import AnimatedLoader from './AnimatedLoader';
import {Link} from 'src/utils/navigation';
import RemixIcon from './RemixIcon';

const textButtonClassName = 'hover:bg-[--secondary-button-bg-color] active:bg-[--button-pressed-color]';

const getVariantClassNames = (variant: string | undefined) => {
  switch (variant) {
    case 'text':
      return textButtonClassName;
    case 'secondary':
      return [textButtonClassName, 'border-[--border-color] border border-solid shadow-button'];
    case 'primary':
      return 'text-white bg-[--dark-text]';
    default:
      return '';
  }
};

const getSizeClassNames = (size: string | undefined) => {
  switch (size) {
    case 'large':
      return 'text-lg px-6 h-12';
    case 'small':
      return 'text-tiny px-3 h-8';
    default:
      return 'text-sm px-4 h-9';
  }
};

export type BaseButtonProps = MotionProps & {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  iconName?: string;
  icon?: any;
  iconSize?: number;
  color?: string;
  style?: React.CSSProperties;
  animated?: boolean;
  variant?: 'text' | 'secondary' | 'primary';
  size?: 'large' | 'small';
  href?: string;
  target?: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit';
  onMouseEnter?: () => void;
  prefetch?: boolean;
  locale?: string;
};

const getButtonComponent = (animated: boolean | undefined, href: string | undefined): any => {
  if (animated) {
    return href
      ? // @ts-ignore
        motion(Link)
      : motion.button;
  }

  return href ? Link : 'button';
};

const BaseButton = forwardRef(
  (
    {
      children,
      label,
      className,
      iconName,
      icon,
      style,
      animated,
      variant,
      iconSize = 18,
      isLoading,
      color,
      size,
      disabled,
      onClick,
      ...otherProps
    }: BaseButtonProps,
    ref: ForwardedRef<any>
  ) => {
    const contentColor = color || (variant === 'primary' ? 'white' : 'var(--dark-text)');

    const Component = getButtonComponent(animated, otherProps.href);
    const IconWrapper = animated ? motion.div : (props: MotionProps) => createElement('div', props);

    return (
      <Component
        {...otherProps}
        onClick={disabled ? undefined : onClick}
        ref={ref}
        className={twMerge(
          'overflow-hidden bg-transparent flex shrink-0 items-center justify-center border-none rounded-md font-medium h-9 no-underline select-none leading-none ring-offset-1 focus-visible:ring-2 focus:ring-gray-200 active:brightness-95 transition',
          getVariantClassNames(variant),
          getSizeClassNames(size),
          disabled && 'opacity-65 cursor-not-allowed',
          isLoading && '[&>*:not(.loading)]:!opacity-0 !text-transparent',
          className
        )}
        style={{color: contentColor, ...style}}
      >
        {iconName || icon ? (
          <IconWrapper layout="position" className={children || label ? 'mr-2 -ml-1' : '-mx-1'}>
            <RemixIcon name={iconName} icon={icon} color={contentColor} size={iconSize} />
          </IconWrapper>
        ) : null}

        {children || label}

        {isLoading ? <AnimatedLoader className="loading" style={{backgroundColor: contentColor}} /> : null}
      </Component>
    );
  }
);

export default BaseButton;
